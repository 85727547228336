var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "d-flex" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mt-3",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")]), _vm._v(" Back ")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-5" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", xl: "8" } },
            [
              _c("full-enhancement", {
                attrs: {
                  "active-offer": _vm.selectedOffer,
                  amount: _vm.amount,
                  item: _vm.nft,
                  "wallet-address": _vm.walletAddress,
                },
                on: { refreshNftAmount: _vm.getNftUserAmount },
              }),
              _vm.unknownItem
                ? _c("div", { staticClass: "w-100" }, [
                    _c("h3", { staticClass: "text-center" }, [
                      _vm._v("- Unknown item -"),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isWalletConnected
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "your-active-offers",
                  attrs: {
                    cols: "12",
                    offset: "0",
                    "offset-xl": "1",
                    xl: "10",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "box-decorated-edges-mini common-bg d-flex flex-column align-center",
                    },
                    [
                      _c("h3", { staticClass: "mt-2 text-center" }, [
                        _vm._v("Your active offers"),
                      ]),
                      _vm.offersLoaded
                        ? _c("div", [
                            _vm.hasAnyOffers
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row flex-wrap align-center justify-center",
                                  },
                                  _vm._l(_vm.userOffers, function (offer) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mini-asset-composition ma-2 pb-2 rounded-xl",
                                        class: {
                                          selected: _vm.selectedOffer === offer,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectOffer(offer)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mini-asset" },
                                          [
                                            _c("mini-enhancement", {
                                              attrs: {
                                                amount: offer.amount,
                                                item: offer.assetDTO,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "asset-quick-info mx-4 pt-2 pb-1 d-flex align-center justify-center",
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "d-inline-block",
                                              staticStyle: { width: "16px" },
                                              attrs: {
                                                src: "/img/scroll_48.png",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("localFormatNumber")(
                                                      Number(offer.price)
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _c("div", [
                                  _c(
                                    "h4",
                                    { staticClass: "text-center my-10" },
                                    [_vm._v("- Nothing found -")]
                                  ),
                                ]),
                          ])
                        : _c("v-progress-circular", {
                            staticClass: "my-6",
                            attrs: {
                              indeterminate: "",
                              size: "100",
                              width: "5",
                            },
                          }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }