<template>
    <v-container>
        <v-row class="d-flex">
            <v-btn @click="$router.go(-1)" class="mt-3">
                <v-icon>mdi-arrow-left</v-icon>
                Back
            </v-btn>
        </v-row>
        <v-row class="mb-5">
            <v-col cols="12" xl="8">
                <full-enhancement
                    :active-offer="selectedOffer"
                    :amount="amount"
                    :item="nft"
                    :wallet-address="walletAddress"
                    @refreshNftAmount="getNftUserAmount"
                />
                <div v-if="unknownItem" class="w-100">
                    <h3 class="text-center">- Unknown item -</h3>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="isWalletConnected">
            <v-col cols="12" offset="0" offset-xl="1" xl="10" class="your-active-offers">
                <div class="box-decorated-edges-mini common-bg d-flex flex-column align-center">
                    <h3 class="mt-2 text-center ">Your active offers</h3>
                    <div v-if="offersLoaded">
                        <div v-if="hasAnyOffers" class="d-flex flex-row flex-wrap align-center justify-center">
                            <div v-for="offer in userOffers"
                                 :class="{'selected': selectedOffer === offer}"
                                 class="mini-asset-composition ma-2 pb-2 rounded-xl"
                                 @click="selectOffer(offer)">
                                <div class="mini-asset">
                                    <mini-enhancement
                                        :amount="offer.amount"
                                        :item="offer.assetDTO"
                                    />
                                </div>
                                <div class="asset-quick-info mx-4 pt-2 pb-1 d-flex align-center justify-center">
                                    <img class="d-inline-block" src="/img/scroll_48.png" style="width: 16px;">
                                    <span class="caption">{{ Number(offer.price) | localFormatNumber }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <h4 class="text-center my-10">- Nothing found -</h4>
                        </div>
                    </div>
                    <v-progress-circular v-else class="my-6" indeterminate size="100"
                                         width="5"></v-progress-circular>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {apiServiceVue} from "../services/apiService.vue";
import EnhancementContract from "../services/contracts/enhancementContract";
import FullEnhancement from "@/components/assets/enhancements/full-enhancement";
import MarketService from "@/services/marketService";
import MiniEnhancement from "@/components/assets/enhancements/mini-enhancement";

export default {
    components: {MiniEnhancement, FullEnhancement},
    data() {
        return {
            id: parseInt(this.$route.params.id),
            nft: {},
            nftOwner: undefined,
            amount: null,
            loadingAmount: false,
            unknownItem: false,
            marketService: undefined,
            userOffers: [],
            offersLoaded: false,
            selectedOffer: undefined,
        };
    },
    mounted() {
        this.getNft(this.id);
        window.addEventListener("reload-view", this.init);
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        walletAddress() {
            return this.web3.address?.toLowerCase();
        },
        hasAnyOffers() {
            return this.userOffers?.length > 0
        },
    },
    methods: {
        init() {
            this.selectedOffer = undefined;
            this.marketService = new MarketService();
            this.getNftUserAmount();
            this.updateUserOffers()
        },
        updateUserOffers() {
            this.offersLoaded = false;
            this.userOffers = [];
            this.marketService.getAllEnhancementOpenOffersForFrom(this.id, this.walletAddress)
                .then(offers => {
                    this.userOffers = offers;
                })
                .catch(() => {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Can not fetch enhancement offers'
                    });
                })
                .finally(() => this.offersLoaded = true)
        },
        selectOffer(offer) {
            this.selectedOffer = offer;
        },
        getNftUserAmount() {
            this.amount = undefined;
            const enhancementContract = new EnhancementContract(this.web3);
            enhancementContract.getMyBalanceOfEnhancementsSingle(this.id)
                .then((res) => {
                    this.amount = parseInt(res);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingAmount = false;
                });
        },
        getNft(id) {
            if ((!id || isNaN(id)) && id !== 0) {
                this.flashMessage.show({
                    status: 'error',
                    message: 'Wrong Enhancement - please return to previous page and try again'
                });
                throw new Error('Enhancement - wrong ID:' + id);
            }
            const url = process.env.VUE_APP_ENHANCEMENT_NFT_SERVER_URL + id;
            apiServiceVue.request(url).then(nft => {
                if (nft && nft.hasOwnProperty(id)) {
                    this.nft = nft[id];
                } else {
                    this.unknownItem = true;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.selected {
    box-shadow: inset 0 0 15px -5px white;
}

.common-bg {
    background-color: rgba(42, 28, 51);
}

.mini-asset-composition {
    cursor: pointer;
}

.asset-quick-info {
    margin-top: -5px;
    background-image: url("/img/market/price-plate.png");
    background-size: 100% 100%;
}

::v-deep .mini-asset-composition:hover {
    .mini-wizard, .mini-enhancement {
        .item-box {
            @include bg-card_universal-hover;

            .item-badge {
                @include bg-card_badge-hover;
            }
        }
    }

    .asset-quick-info {
        background-image: url("/img/market/price-plate-hover.png");
    }
}
</style>
